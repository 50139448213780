/**
 * Models for the player data.
 *
 * @stable
 */

import { Answers as AnswersEnum } from '@shared/enums/answers.enum';

import { PlanId } from '@shared/models/plan.model';

import { TeamData } from '@shared/models/account.model';
import { LanguagesData } from '@shared/models/locale.model';

import {
  DesignData,
  OutcomeData,
  QuestionData,
  ReleaseData,
  SharingData,
  SurveyData,
  SurveyScoring,
  TriggerData,
  ViewState,
} from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';

export function getOS(): 'ios' | 'android' | 'desktop' {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'ios';
  }

  return 'desktop';
}

export interface PublicData {
  plan: PlanId;
}

export interface PlayerData {
  answers: PlayerAnswers | null;

  team: TeamData | null;
  public: PublicData | null;

  survey: SurveyData | null;
  design: DesignData | null;
  release: ReleaseData | null;
  sharing: SharingData | null;
  scoring: SurveyScoring | null;
  triggers: TriggerData[] | null;
  outcomes: OutcomeData[] | null;
  questions: QuestionData[] | null;

  languages: LanguagesData | null;
}

export interface PlayerState {
  view: ViewState;
  $key?: string;
}

export interface PlayerAnswer {
  value?: string;
  progress?: number;

  item: QuestionData;
}

export interface PlayerAnswers {
  [key: string]: string;
}

export interface PlayerOutcome {
  score?: number;
  correct?: { correct: number; max: number };

  item: OutcomeData;
}

export const enum DataType {
  Answers,
  Results,
  Answerer,
}

export interface AnswerSession {
  session?: string;
  identity?: string;
  answerer?: number | string;
}

export interface Answerer {
  id: number | string;
  key: string;
  auth: string;
  teamKey: string;
  linkKey: string;
  identity: string;
  timestamp: number;
  activated: number;
  started: number;
  finished: number;
  progress: number;
  location: string;
  language: string;
  hashtags: string[];
}

export interface Answer {
  activated: number;
  answered: number;
  finished: number;
  question: string;
  questionType: Questions;
  session: string;
  started: number;
  survey: string;
  synced: number | false;
  timestamp: number;
  type: AnswersEnum;
  value?: string | null;
  scaled?: string | null;
}

export interface Answers {
  [questionKey: string]: Answer;
}

export interface Result {
  order: number;
  score: number;
  outcome: string;
  synced: number | false;
  timestamp: number;
}

export interface Results {
  [index: string]: Result;
}

export interface SessionData<T> {
  [sessionKey: string]: T;
}

export interface PlayerSurveyData<T> {
  [surveyKey: string]: SessionData<T>;
}

export type PlayerStorageData = Answers | Results | Answerer;

export type PlayerStorageItem = Answer | Result | Answerer;

export interface GetAnswerIdResponse {
  answerer: Answerer;
  countryCode: string;
  timestamp?: number;
  identity?: string;
  answers?: Answers;
  status?: 'closed' | 'identity-required';
  restartDelay?: number;
  restartInactive?: number;
}

export enum PlayerEventType {
  Start = 'start',
  Answer = 'answer',
  Complete = 'complete',
  Restart = 'restart',
  Outcome = 'outcome',
}

export interface PlayerEventData {
  team: string;
  survey: string;
  linkKey: string;
  answererId: string;
  timestamp: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PlayerStartEventData extends PlayerEventData {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PlayerRestartEventData extends PlayerEventData {}

export interface PlayerAnswerData {
  answer: string;
  question: QuestionData;
  answerType: AnswersEnum;
}

export interface PlayerAnswerEventData extends PlayerEventData, PlayerAnswerData {
  progress: number;
}

export interface PlayerOutcomeData {
  score: number;
  outcome: OutcomeData;
}

export interface PlayerOutcomeEventData extends PlayerEventData {
  outcomes: PlayerOutcomeData[];
}

export interface PlayerCompleteEventData extends PlayerEventData {
  progress: number;
  answers: PlayerAnswerData[];
  outcomes: PlayerOutcomeData[];
}

export interface PlayerEvent {
  type: PlayerEventType;
  data: PlayerEventData;
}

export interface PlayerStartEvent extends PlayerEvent {
  type: PlayerEventType.Start;
  data: PlayerStartEventData;
}

export interface PlayerAnswerEvent extends PlayerEvent {
  type: PlayerEventType.Answer;
  data: PlayerAnswerEventData;
}

export interface PlayerCompleteEvent extends PlayerEvent {
  type: PlayerEventType.Complete;
  data: PlayerCompleteEventData;
}

export interface PlayerRestartEvent extends PlayerEvent {
  type: PlayerEventType.Restart;
  data: PlayerRestartEventData;
}

export interface PlayerOutcomeEvent extends PlayerEvent {
  type: PlayerEventType.Restart;
  data: PlayerOutcomeEventData;
}

export interface WhyFinderRequestData {
  temperature: number;
  messages: InterviewMessageData[];
  parameters: {
    system: [
      language: string,
      answerType: string,
      answer: string,
      questionTypeHelper: string,
      questionTitle: string,
      maxQuestions: string,
      customInstructions: string,
    ];
  };
}

export interface InterviewData {
  temperature: number;
  messages: InterviewMessageData[];
  rootQuestion: RootQuestionData;
  language: string;
  maxQuestions: number;
}

export interface InterviewMessageData {
  role: 'system' | 'user' | 'assistant' | 'function';
  message: string;
}

export interface RootQuestionData {
  title: string;
  type: string;
  answer: string | number;
  answerType: string;
}
