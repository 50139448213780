<div class="z-fx-col container">
  <form #inputFormPhone *ngIf="data?.type === Questions.INPUT_PHONE" class="z-fx form" [formGroup]="phoneFormGroup">
    <mat-form-field class="field-phone-country-select" floatLabel="never">
      <mat-select
        class="z-fx-col z-fx-center-center country-select-dropdown"
        #phoneNumberCountry
        formControlName="country"
        [aria-label]="'Phone number country'"
        [(value)]="phoneCountry"
      >
        <mat-select-trigger class="z-fx z-fx-start-center z-fx-gap-8 selected-item">
          <span
            class="z-input-phone-flag zef-flag flag-select-trigger"
            [ngClass]="'zef-flag-' + phoneCountry.toLowerCase()"
          ></span>
          <span class="z-input-phone-code">+{{ country[phoneCountry.toUpperCase()].dial }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let country of countries" [value]="country.code">
          <span class="z-fx z-fx-start-center z-fx-gap-8 country-option">
            <span class="z-input-phone-flag zef-flag" [ngClass]="'zef-flag-' + country.code.toLowerCase()"></span>
            <span class="z-input-phone-name">{{ country.name }}</span>
            <span class="z-input-phone-code">+{{ country.dial }}</span>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="z-fx-grow phone-field" floatLabel="never">
      <input
        #phoneNumber
        [class.ng-invalid]="phoneFormGroup?.hasError('phone')"
        matInput
        [placeholder]="data.inputField?.hint | translateText: 'inputFieldHint' : data"
        formControlName="number"
        [attr.aria-label]="data.title | translateText: 'title' : data | plainText"
        [attr.aria-invalid]="phoneFormGroup?.hasError('phone')"
      />
      <div class="mat-error" *ngIf="phoneFormGroup?.hasError('phone')">
        {{ 'Hmm… The Phone number doesn’t look valid.' | translateText: 'inputPhoneErrorText' }}
      </div>
    </mat-form-field>
  </form>
  <form #inputForm *ngIf="data?.type !== Questions.INPUT_PHONE" class="form">
    <mat-form-field class="field" floatLabel="never">
      <ng-container *ngIf="data?.type === Questions.FREE_TEXT">
        <textarea
          #input
          matInput
          autoSize
          [maxLength]="getCharsLimit()"
          [autoSizeMinRows]="4"
          [placeholder]="data.inputField?.hint | translateText: 'inputFieldHint' : data"
          [name]="'free_text'"
          [ngModel]="answer"
          [attr.aria-label]="data.title | translateText: 'title' : data | plainText"
          [ngModelOptions]="{ updateOn: 'change' }"
          (ngModelChange)="onValueChanged($event)"
        ></textarea>
        <div
          *ngIf="getCharsLimit() && input.value.length / getCharsLimit() >= 0.8"
          aria-live="polite"
          class="input-limit"
        >
          {{ input.value.length }} / {{ getCharsLimit() }}
        </div>
      </ng-container>
      <ng-container *ngIf="data?.type === Questions.INPUT_EMAIL">
        <input
          matInput
          [formControl]="emailFormControl"
          [placeholder]="data.inputField?.hint | translateText: 'inputFieldHint' : data"
          [name]="'input_email'"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [attr.aria-label]="data.title | translateText: 'title' : data | plainText"
          (ngModelChange)="onEmailValueChanged($event)"
        />
        <mat-error *ngIf="emailFormControl.hasError('email')">
          {{ 'Please enter a valid email address' | translateText: 'inputEmailErrorText' }}
        </mat-error>
      </ng-container>
      <ng-container *ngIf="data?.type === Questions.INPUT_NUMBER">
        <input
          matInput
          type="number"
          [placeholder]="data.inputField?.hint | translateText: 'inputFieldHint' : data"
          [name]="'input_number'"
          [ngModel]="answer"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [attr.aria-label]="data.title | translateText: 'title' : data | plainText"
          (ngModelChange)="onValueChanged($event)"
        />
      </ng-container>
      <ng-container *ngIf="data?.type === Questions.INPUT_URL">
        <input
          matInput
          [formControl]="urlFormControl"
          [placeholder]="data.inputField?.hint | translateText: 'inputFieldHint' : data"
          [name]="'input_url'"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [attr.aria-label]="data.title | translateText: 'title' : data | plainText"
          (ngModelChange)="onUrlValueChanged($event)"
        />
        <mat-error *ngIf="urlFormControl.hasError('url')">
          {{ 'Please enter a valid url address' | translateText: 'inputURLErrorText' }}
        </mat-error>
      </ng-container>
    </mat-form-field>
  </form>
</div>
