<!--
  Welcome card view with header and content elements.

  @unstable
-->

<div class="z-container-box">
  <div class="z-content-wrapper z-fx-col">
    <div class="z-content-area z-fx-col">
      <div class="z-content-area-top z-fx z-fx-between-center">
        <div class="z-language-select-container z-fx z-fx-auto z-fx-end-center">
          <div #lb *ngIf="showLanguageSelector" class="z-language-box z-fx-col z-fx-center-end">
            <div
              class="z-dropdown-preview z-fx z-fx-center-center z-fx-gap-4"
              [style.color]="(ss.colors | async).moodContrast"
              (click.s)="lb.open = true"
            >
              <figure [ngClass]="flagClass(lm.currentLanguageCode)"></figure>

              <span class="z-lang-name" [style.color]="(ss.colors | async).text">{{ lm.currentLanguageName }}</span>
              <mat-icon fontSet="zefplayer" class="z-dropdown-icon" [style.color]="(ss.colors | async).text">
                arrow_down
              </mat-icon>
            </div>

            <div class="z-dropdown-holder z-fx-auto">
              <div *ngIf="lb.open" class="z-language-overlay">
                <div class="z-dropdown-container">
                  <language-select
                    [locales]="lm.languages"
                    [selected]="lm.currentLanguageCode"
                    [clickOutsideActive]="lb.open"
                    (clickOutside)="lb.open = false"
                    (select)="lb.open = false; lm.setLanguage($event)"
                  ></language-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="(ss.logo | async).url"
        class="z-content-area-logo z-fx-col z-fx-center-center z-fx-flex-center"
        [style.margin-top.px]="(ss.viewRect$ | async)?.height * 0.08"
      >
        <img class="z-survey-logo" [src]="(ss.logo | async).url" alt="logo" />
      </div>

      <ng-container *ngIf="ss.survey | async as survey">
        <div
          *ngIf="survey.title || survey.description"
          class="z-content-area-texts"
          [style.margin-top.px]="(ss.viewRect$ | async)?.height * 0.08"
        >
          <div
            *ngIf="survey.title"
            class="z-title-text z-texts-header"
            [style.color]="(ss.colors | async).text80"
            [innerHtml]="survey.title | translateText: 'surveyTitle' | formatText | safeHtml"
          ></div>

          <div
            *ngIf="survey.description"
            class="z-welcome-text z-text-paragraph"
            [style.color]="(ss.colors | async).text80"
            [innerHTML]="survey.description || '' | translateText: 'surveyDescription' | formatText | safeHtml"
          ></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
