<div class="z-fx-col container" [class.smileyMargin]="data.sliderSmileys">
  <div class="z-fx-col labels">
    <div class="axis-label">{{ data.sliderLabels.axis | translateText: 'axis' : data }}</div>
    <div class="z-fx z-fx-between-center axis-ends">
      <div class="min">{{ data.sliderLabels.min | translateText: 'min' : data }} (0)</div>
      <div class="max">{{ data.sliderLabels.max | translateText: 'max' : data }} (10)</div>
    </div>
  </div>
  <mat-slider
    #xAxis
    class="slider"
    [ngClass]="smiley(xAxis.value)"
    [min]="0"
    [max]="10"
    [step]="1"
    [value]="answer"
    thumbLabel
    [attr.aria-label]="ariaLabel | plainText"
    (change)="onValueChanged($event)"
  ></mat-slider>
</div>
