/**
 * Fade in / out animation for components.
 *
 * @unstable
 */

import { animate, state, style, transition, trigger } from '@angular/animations';

export const WaitOut = trigger('waitOut', [
  transition(':leave', [animate('0ms 200ms', style({ opacity: 0 }))]),
  transition(':enter', [style({ opacity: 0 }), animate('0ms 200ms', style({ opacity: '*' }))]),
]);

export const FadeInOut = trigger('fadeInOut', [
  state(
    'in',
    style({
      opacity: '*',
    }),
  ),

  state(
    'out',
    style({
      opacity: 0,
    }),
  ),

  transition('void => *', [
    style({
      opacity: 0,
    }),
    animate(
      '500ms',
      style({
        opacity: '*',
      }),
    ),
  ]),

  transition('* => void', [
    style({
      opacity: '*',
    }),
    animate(
      '500ms',
      style({
        opacity: 0,
      }),
    ),
  ]),

  transition('out => in', [
    style({
      opacity: 0,
    }),
    animate(
      '800ms',
      style({
        opacity: '*',
      }),
    ),
  ]),

  transition('in => out', [
    style({
      opacity: '*',
    }),
    animate(
      '800ms',
      style({
        opacity: 0,
      }),
    ),
  ]),
]);

export const ImageFadeInOut = trigger('fadeInOut', [
  state(
    'in',
    style({
      opacity: '*',
    }),
  ),

  state(
    'out',
    style({
      opacity: 0,
    }),
  ),

  transition('void => *', [
    style({
      opacity: 0,
    }),
    animate(
      '1000ms',
      style({
        opacity: '*',
      }),
    ),
  ]),

  transition('* => void', [
    style({
      opacity: '*',
    }),
    animate(
      '1000ms',
      style({
        opacity: 0,
      }),
    ),
  ]),

  transition('out => in', [
    style({
      opacity: 0,
    }),
    animate(
      '1000ms',
      style({
        opacity: '*',
      }),
    ),
  ]),

  transition('in => out', [
    style({
      opacity: '*',
    }),
    animate(
      '1000ms',
      style({
        opacity: 0,
      }),
    ),
  ]),
]);
