<div class="z-fx-col container">
  <ng-container *ngIf="questionData?.type === Questions.CHOICE_SINGLE">
    <mat-radio-group
      class="radios"
      [(ngModel)]="radioAnswer"
      [attr.aria-label]="data?.title | translateText: 'title' : data | plainText"
      (change)="onSingleSelectionValueChanged($event)"
    >
      <mat-radio-button
        class="radio"
        value="after"
        *ngFor="let option of options; trackBy: trackByChoice"
        [value]="option.$key"
      >
        <span class="z-dropdown-option">{{ option.content | translateText: 'choice' : questionData : option }}</span>
        <input
          *ngIf="option.comment && answer.indexOf(option.$key) >= 0"
          matInput
          #otherOptionCheckbox
          class="other-option"
          [placeholder]="('Other' | translateText: 'otherOption') + '...'"
          [(ngModel)]="otherOptionAnswer"
          [attr.aria-label]="'Other' + '...'"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (ngModelChange)="otherOptionValueChanged(option.$key, $event)"
        />
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
  <ng-container *ngIf="questionData?.type === Questions.CHOICE_MULTI || questionData?.type === Questions.CHOICE_TEXT">
    <div *ngIf="questionData?.showStatus !== false && questionData?.choiceLimit > 1" class="status-text">
      {{
        'Select up to ' + questionData?.choiceLimit | translateText: 'choiceSelectUpTo' : [questionData?.choiceLimit]
      }}
    </div>
    <div *ngFor="let option of options; trackBy: trackByChoice" class="checkbox">
      <mat-checkbox
        [(ngModel)]="multiSelections[option.$key]"
        (ngModelChange)="onMultiSelectionValueChanged()"
        [disabled]="limitReached && !multiSelections[option.$key]"
      >
        {{ option.content | translateText: 'choice' : questionData : option }}
        <input
          *ngIf="option.comment && multiSelections[option.$key]"
          #otherOptionCheckbox
          matInput
          class="other-option"
          [placeholder]="('Other' | translateText: 'otherOption') + '...'"
          [ngModel]="otherOptionAnswer"
          [attr.aria-label]="'Other' + '...'"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (ngModelChange)="otherOptionValueChanged(option.$key, $event)"
        />
      </mat-checkbox>
    </div>
  </ng-container>
  <ng-container *ngIf="questionData?.type === Questions.INPUT_CHECKBOX">
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="singleCheckbox" (ngModelChange)="onSingleCheckboxValueChanged()">
        {{ questionData?.inputField?.hint | translateText: 'inputFieldHint' : questionData }}
      </mat-checkbox>
    </div>
  </ng-container>
</div>
