<div class="z-fx-col container">
  <mat-select
    *ngIf="!touchDevice"
    class="dropdown"
    [aria-label]="data?.title | translateText: 'title' : data | plainText"
    [placeholder]="data.inputField?.hint | translateText: 'inputFieldHint' : data"
    [value]="answer"
    (selectionChange)="onValueChanged($event.value)"
  >
    <mat-option *ngFor="let option of options; trackBy: trackByOption" [value]="option.$key">
      <span class="z-dropdown-option">{{ option.content | translateText: 'choice' : questionData : option }}</span>
    </mat-option>
  </mat-select>
  <div *ngIf="touchDevice" class="mobile-dropdown">
    <select
      class="dropdown"
      matNativeControl
      [attr.aria-label]="data?.title | translateText: 'title' : data | plainText"
      [ngModel]="answer"
      (ngModelChange)="onValueChanged($event)"
    >
      <option value="" disabled>{{ data.inputField?.hint | translateText: 'inputFieldHint' : data }}</option>
      <option *ngFor="let option of options; trackBy: trackByOption" [value]="option.$key">
        {{ option.content | translateText: 'choice' : questionData : option }}
      </option>
    </select>
  </div>
</div>
