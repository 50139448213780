<div class="container">
  <div class="content">
    <div *ngIf="!showOutcomes" class="z-fx z-fx-between-center headings">
      <h1 *ngIf="(lm.loadingLanguage | async) === false" class="z-fx z-fx-start-center header">
        {{ 'Accessibility Mode' | translateText: 'accessibilityMode' }}
      </h1>
      <div *ngIf="(lm.loadingLanguage | async) === false" class="z-fx z-fx-end-center button-area">
        <button class="exit-button" (click)="exitAccessibility()">
          {{ 'Exit Accissibility Mode' | translateText: 'exitAccessibilityMode' }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="!showOutcomes && lm?.languages | pairs as languages">
      <div *ngIf="languages.length > 1" class="language-selector-area">
        <mat-select
          class="z-fx-col z-fx-center-center language-selector"
          [aria-label]="'Language'"
          [placeholder]="'Select language' | translateText: 'selectLanguage'"
          [value]="lm?.currentLanguage"
          (selectionChange)="onLanguageChanged($event)"
        >
          <mat-select-trigger class="z-fx z-fx-start-center z-fx-gap-8 selected-item">
            <figure [ngClass]="flagClass(lm?.currentLanguage)"></figure>
            <span class="dropdown-option">
              {{
                lm &&
                  lm.languages &&
                  lm.currentLanguage &&
                  lm.languages[lm.currentLanguage] &&
                  (lm.languages[lm.currentLanguage].locale || lm.languages[lm.currentLanguage].name)
              }}
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let lang of languages" [value]="lang[0]">
            <span class="z-fx z-fx-start-center z-fx-gap-8">
              <figure [ngClass]="flagClass(lang[0])"></figure>
              <span class="dropdown-option">{{ lang[1]?.locale || lang[1]?.name }}</span>
            </span>
          </mat-option>
        </mat-select>
      </div>
    </ng-container>

    <div class="survey-content" aria-live="polite">
      <div
        *ngIf="!loaded || (lm.loadingLanguage | async)"
        class="z-fx z-fx-center-center loading"
        role="alert"
        aria-busy="true"
      >
        <mat-spinner></mat-spinner>
      </div>

      <div *ngIf="(lm.loadingLanguage | async) === false && showOutcomes === false" class="questions-content">
        <ng-container *ngIf="ss?.survey | async as survey">
          <div *ngIf="survey.welcome" class="question">
            <h2
              class="question-title"
              [innerHtml]="survey.title | translateText: 'surveyTitle' | formatText | safeHtml"
            ></h2>
            <p
              class="answer-area info"
              [innerHTML]="survey.description || '' | translateText: 'surveyDescription' | formatText | safeHtml"
            ></p>
            <div *ngIf="survey.policy" class="policy-container">
              <span>{{ 'By continuing, you agree to Zeffi’s' | translateText: 'privacyPolicyText' }}</span>
              <a [href]="'https://zef.fi/security-and-privacy/' | translateText: 'privacyPolicyLink'" target="_blank">
                {{ 'Privacy Policy' | translateText: 'privacyPolicy' }}
              </a>
            </div>
          </div>
        </ng-container>

        <div
          *ngFor="let question of ss?.cardQuestions | async; trackBy: trackBy$Key"
          class="question"
          [class.answered]="(ss.answers | async)[question.$key] != null"
        >
          <ng-container
            *ngIf="{
              showTitle:
                question.showTitle &&
                question.type !== Questions.AI_INTERVIEWER &&
                question.type !== Questions.ESKO_WHY_FINDER &&
                !!(question.title | translateText: 'title' : question | formatText | safeHtml),
              isRequired: question.required,
            } as conditions"
          >
            <h2 *ngIf="conditions.showTitle || conditions.isRequired" class="question-title z-fx-sc z-fx-gap-8">
              <span *ngIf="conditions.showTitle" class="question-number">
                {{ questionNumber(ss?.filteredQuestions | async, question) }}
              </span>
              <span
                *ngIf="conditions.showTitle"
                [innerHtml]="question.title | translateText: 'title' : question | formatText | safeHtml"
              ></span>
              <mat-icon
                *ngIf="question.required && (ss.answers | async)[question.$key] == null"
                fontSet="zefplayer"
                class="mandatory-icon"
              >
                mandatory_on
              </mat-icon>
              <span
                *ngIf="!reviewModeOn && question.required && (ss.answers | async)[question.$key] == null"
                class="cdk-visually-hidden"
              >
                {{ 'This answer is requested' | translateText: 'requiredQuestion' }}
              </span>
              <mat-icon *ngIf="(ss.answers | async)[question.$key] != null" fontSet="zefplayer" class="check-icon">
                check
              </mat-icon>
            </h2>
          </ng-container>
          <div
            *ngIf="reviewModeOn && question.required && (ss.answers | async)[question.$key] == null"
            class="required-banner"
          >
            <span>*&nbsp;</span>
            <span>{{ 'This answer is requested' | translateText: 'requiredQuestion' }}</span>
          </div>
          <div class="answer-area" [class.info]="question.type === Questions.INFO_TEXT || Questions.group(question)">
            <a-slider-2d
              *ngIf="question.type === Questions.SLIDER_2D"
              [questionData]="question"
              [groupData]="groupData(question) | async"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-slider-2d>
            <a-slider-1d
              *ngIf="question.type === Questions.SLIDER_1D || question.type === Questions.SLIDER_1V"
              [questionData]="question"
              [groupData]="groupData(question) | async"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-slider-1d>
            <a-slider-1r
              *ngIf="question.type === Questions.SLIDER_1R"
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-slider-1r>
            <a-slider-nps
              *ngIf="question.type === Questions.SLIDER_NPS || question.type === Questions.SLIDER_E_NPS"
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-slider-nps>
            <a-info
              *ngIf="question.type === Questions.INFO_TEXT || Questions.group(question)"
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-info>
            <a-input
              *ngIf="
                question.type === Questions.FREE_TEXT ||
                question.type === Questions.INPUT_EMAIL ||
                question.type === Questions.INPUT_PHONE ||
                question.type === Questions.INPUT_URL ||
                question.type === Questions.INPUT_NUMBER
              "
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-input>
            <a-dropdown
              *ngIf="question.type === Questions.INPUT_DROPDOWN || question.type === Questions.INPUT_NUMERIC"
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              [touchDevice]="touchDevice"
              (answerChanged)="onAnswerChanged($event)"
            ></a-dropdown>
            <a-choice
              *ngIf="
                question.type === Questions.CHOICE_SINGLE ||
                question.type === Questions.CHOICE_MULTI ||
                question.type === Questions.CHOICE_TEXT ||
                question.type === Questions.INPUT_CHECKBOX
              "
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-choice>
            <a-choice-picture
              *ngIf="question.type === Questions.CHOICE_PICTURE"
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-choice-picture>
            <a-file-upload
              *ngIf="question.type === Questions.FILE_UPLOAD"
              [questionData]="question"
              [answerValue]="(ss.answers | async)[question.$key]"
              (answerChanged)="onAnswerChanged($event)"
            ></a-file-upload>
            <div *ngIf="question.type === Questions.AI_INTERVIEWER">
              <a-ai-interviewer
                [placeholder]="question.inputField?.hint | translateText: 'inputFieldHint' : question"
                [questionData]="question"
                [answerValue]="(ss.answers | async)[question.$key]"
                (answerChange)="onAnswerChanged($event, question)"
                (saveSummaries)="saveSummaries($event, question)"
              ></a-ai-interviewer>
            </div>
            <div *ngIf="question.type === Questions.ESKO_WHY_FINDER">
              <a-why-finder
                [placeholder]="question.inputField?.hint | translateText: 'inputFieldHint' : question"
                [questionData]="question"
                [answerValue]="(ss.answers | async)[question.$key]"
                (answerChange)="onAnswerChanged($event, question)"
                (saveSummaries)="saveSummaries($event, question)"
              ></a-why-finder>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="loaded && (lm.loadingLanguage | async) === false && !showOutcomes"
        class="z-fx-col z-fx-center-center finish-button-area"
      >
        <button class="finish-button" (click)="showResults()" aria-expanded="true">
          {{ 'Finish' | translateText: 'finishButton' }}
        </button>
        <div *ngIf="reviewModeOn && (requiredUnanswered | async) as unanswered" class="review-text" aria-live="polite">
          <ng-container *ngIf="unanswered.length === 1">
            1 {{ 'answer needs completing' | translateText: 'reviewSingle' }}
          </ng-container>

          <ng-container *ngIf="unanswered.length > 1">
            {{ unanswered.length }} {{ 'answers need completing' | translateText: 'reviewMulti' }}
          </ng-container>
        </div>
      </div>

      <div *ngIf="showOutcomes && (lm.loadingLanguage | async) === false" class="result-page">
        <div class="z-fx-col z-fx-gap-4 z-fx-center-start change-answers-link-area">
          <button class="change-answers-link" (click)="onChangeAnswers()">
            {{ 'Change answers' | translateText: 'changeAnswers' }}
          </button>
          <button *ngIf="ss.restartDelay$ | async as delay" class="change-answers-link" (click)="onResetAnswers()">
            {{ 'Reset' | translateText: 'reset' }} ({{ delay }})
          </button>
        </div>
        <ng-container *ngIf="(ss.survey | async)?.results as resultType">
          <ng-container *ngIf="resultType === Outcomes.GOODBYE && (ss.thanksOutcome | async) as outcome">
            <a-outcome [outcomeData]="outcome" (newAnswerer)="onResetAnswers()"></a-outcome>
          </ng-container>
          <ng-container *ngIf="resultType === Outcomes.OUTCOME">
            <div
              *ngFor="
                let outcome of ss.scoredOutcomes | async;
                trackBy: trackByScoredOutcome;
                let index = index;
                let last = last
              "
              class="outcome-card"
            >
              <a-outcome
                [outcomeData]="outcome.item"
                [score]="outcome.score"
                (newAnswerer)="onResetAnswers()"
              ></a-outcome>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
