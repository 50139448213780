/**
 * Slider 2D component with labels and values showing.
 *
 * @unstable
 */

import { BehaviorSubject, combineLatest, concat, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, mapTo, shareReplay, startWith, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';
import { QuestionData } from '@shared/models/survey.model';
import { raceFromEvent } from '@shared/operators/race-from-event.operator';

import { getOS } from '@player/shared/models/player.model';
import { sliderIntro } from '@player/shared/animations/slider-intro.anim';
import { SliderLabel } from '@player/shared/services/slider-label.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

type EventPosition = { left: number; top: number };

@Component({
  selector: 'slider-2d',
  templateUrl: './slider-2d.component.html',
  styleUrls: ['./slider-2d.component.scss'],
  providers: [SliderLabel],
  animations: sliderIntro,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Slider2D implements OnInit, AfterViewInit, OnDestroy {
  readonly active = new BehaviorSubject<boolean>(false);
  readonly moving = new BehaviorSubject<boolean>(false);
  readonly hovering = new BehaviorSubject<boolean>(false);
  readonly answering = new BehaviorSubject<boolean>(false);
  readonly introPhase = new BehaviorSubject<string>('');

  public position?: Observable<EventPosition>;
  public currentValue?: Observable<[number, number]>;
  public groupAnswers?: Observable<{ no: number; val: string[] }[]>;

  public colors: {
    primary?: string;
    primary30?: string;
    texts?: string;
    buttons?: string;
    buttonsContrast?: string;
  } = {};

  public smileysX?: string[];
  public smileysY?: string[];

  public readonly axes = ['x', 'y'];
  public readonly sidesX = ['left', 'right'];
  public readonly sidesY = ['top', 'bottom'];
  public readonly lineSides = ['around', ...this.sidesX, ...this.sidesY];
  public readonly arrows = ['first', 'second', 'third'];
  public readonly heads = ['up', 'down'];

  @Input() no: number;
  @Input() inList: boolean = false;
  @Input() disabled: boolean = false;
  @Input() flexSize: boolean = false;
  @Input() set isActive(isActive: boolean) {
    this._isActive = isActive;

    if (isActive) {
      this.resetValue.next(this.getInitialPosition());
    }

    this.active.next(isActive);
  }
  get isActive(): boolean {
    return this._isActive;
  }

  public showIntro?: Observable<boolean>;

  @Input() view?: string;
  @Input() answerValue: string[] | null = null;
  @Input() groupData?: QuestionData;
  @Input() questionData?: QuestionData;

  @Output() answerValueReady = new EventEmitter<void>();
  @Output() answerValueChange = new EventEmitter<[number, number] | null>();
  @Output() dragChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('container')
  container?: ElementRef<HTMLElement>;
  @ViewChild('answerArea')
  answerArea?: ElementRef<HTMLElement>;
  @ViewChild('SliderThumb', { read: ElementRef })
  thumb?: ElementRef<HTMLElement>;

  get data(): QuestionData {
    return this.groupData || this.questionData || new QuestionData();
  }

  private areaRect?: ClientRect;
  private containerRect?: ClientRect;

  private rounder = 10 ** 10;
  private offset: { x?: number; y?: number } = {};
  private resetValue = new Subject<EventPosition>();
  private _isActive: boolean = false;
  private blockReset: number = 0;
  private ro: ResizeObserver;

  constructor(
    readonly ss: SurveyStore,
    readonly cd: ChangeDetectorRef,
    readonly el: ElementRef,
  ) {}

  @HostListener('window:resize.p')
  onWindowResize(): void {
    this.setContainerSize();

    if (this.answerArea) {
      this.areaRect = this.answerArea.nativeElement.getBoundingClientRect();
    }

    this.resetValue.next(this.getInitialPosition());
  }

  ngOnInit(): void {
    const { sliderLabelsX, sliderLabelsY } = this.data;

    if (sliderLabelsX && sliderLabelsY) {
      this.smileysX = sliderLabelsX.smileys || [];
      this.smileysY = sliderLabelsY.smileys || [];

      if (!Array.isArray(this.smileysX)) {
        this.smileysX = Object.values(this.smileysX);
      }

      if (!Array.isArray(this.smileysY)) {
        this.smileysY = Object.values(this.smileysY);
      }

      this.smileysY = [...this.smileysY].reverse();
    }

    if (this.groupData && this.questionData && this.groupData.type === Questions.GROUP_SCORED) {
      this.groupAnswers = combineLatest([this.ss.normalQuestions, this.ss.answers]).pipe(
        map(([questions, answers]) =>
          questions
            .filter(
              (q: QuestionData) =>
                answers[q.$key] != null &&
                q.$key !== this.questionData.$key &&
                q.type === this.questionData.type &&
                this.groupData.$key === q.group,
            )
            .map((question) => ({
              no: questions.indexOf(question) + 1,
              val: answers[question.$key].split(';'),
            })),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.answerArea) {
      const mouseLeave = this.mergeEvents(this.answerArea.nativeElement, ['mouseleave']).pipe(
        tap(() => this.hovering.next(false)),
      ) as Observable<EventPosition>;
      const mouseOver = this.mergeEvents(this.answerArea.nativeElement, ['mouseenter'], true).pipe(
        tap(() => this.hovering.next(true)),
        filter(() => !this.hasAnswer()),
        switchMap((position) =>
          concat(
            this.mergeEvents(this.answerArea.nativeElement, ['mousemove']).pipe(
              takeUntil(mouseLeave),
              startWith(position),
            ),
            of(this.getInitialPosition()),
          ),
        ),
        filter(() => !this.hasAnswer()),
      ) as Observable<EventPosition>;

      this.position = merge(mouseOver, merge(this.fromPointerDown(), this.resetValue)).pipe(
        filter(() => this.isActive),
      );

      this.currentValue = this.position.pipe(
        map((position) => this.getValueFromPosition(position)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );

      setTimeout(() => {
        this.ro = new ResizeObserver(() => {
          this.onWindowResize();
          setTimeout(() => this.onWindowResize(), 250);
        });
        this.ro.observe(this.el.nativeElement);

        this.setContainerSize();

        this.areaRect = this.answerArea.nativeElement.getBoundingClientRect();

        this.showIntro = combineLatest([
          this.active,
          this.ss.answers,
          this.ss.cardQuestions,
          merge(mouseOver.pipe(mapTo(false)), mouseLeave.pipe(mapTo(true))).pipe(startWith(true)),
        ]).pipe(
          filter(([active]) => active),
          map(([, answers, questions, canShow]) => {
            if (!this.questionData || answers[this.questionData.$key] != null || !canShow) {
              return false;
            }

            return !questions.some(
              (question) => question.type === Questions.SLIDER_2D && answers[question.$key] != null,
            );
          }),
          tap((showIntro) => this.introPhase.next(showIntro ? 'x' : '')),
          shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.cd.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.ro) {
      this.ro.disconnect();
    }
  }

  getSmiley(axis: string, pos: EventPosition): string {
    const smileys = axis === 'x' ? this.smileysX : this.smileysY;
    return (smileys && smileys[Math.round((smileys.length - 1) * (axis === 'x' ? pos.left : pos.top))]) || '';
  }

  getTransformPos({ left, top }: EventPosition, axis?: string): string | void {
    // using transform translate for better performance than position left / top
    if (this.areaRect) {
      if (axis === 'x') {
        return `translateX(${left * this.areaRect.width}px)`;
      } else if (axis === 'y') {
        return `translateY(${top * this.areaRect.height}px)`;
      } else {
        return `translate(${left * this.areaRect.width}px, ${top * this.areaRect.height}px)`;
      }
    }
  }

  onTouchMove(event: UIEvent): void {
    if (getOS() === 'ios') {
      event.preventDefault();
    }
  }

  private setContainerSize(): void {
    if (this.container) {
      this.container.nativeElement.style.width = '100%';
      this.containerRect = this.container.nativeElement.getBoundingClientRect();
      this.container.nativeElement.style.width = this.containerRect.height + 'px';
    }
  }

  private fromPointerDown(): Observable<EventPosition> {
    return this.mergeEvents(this.answerArea.nativeElement, ['mousedown', 'touchstart'], true, true).pipe(
      tap(() => {
        this.introPhase.next('');
        this.answering.next(true);

        if (this.inList) {
          this.dragChange.next(true);
        }
      }),
      switchMap((position) => this.fromPointerMove(position)),
      startWith(this.getInitialPosition()),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private fromPointerMove(position: EventPosition): Observable<EventPosition> {
    return this.mergeEvents(document, ['mousemove', 'touchmove']).pipe(
      startWith(position),
      tap((newPos) => {
        if (!this.moving.getValue() && (position.left !== newPos.left || position.top !== newPos.top)) {
          // mouse have been moved, cannot compare object ref because chrome fires mousemove on mousedown
          this.moving.next(true);
        }
      }),
      takeUntil(this.fromPointerUp()),
    );
  }

  private fromPointerUp(): Observable<EventPosition> {
    return this.mergeEvents(document, ['mouseup', 'touchend', 'touchcancel']).pipe(
      take(1),
      switchMap(() => this.position.pipe(take(1))),
      tap((pos) => {
        let newPosition: [number, number] | null = null;

        if (this.answerValue != null && !this.moving.getValue() && this.offset.x != null && !this.blockReset) {
          this.answerValue = null;
          this.resetValue.next(this.getInitialPosition());
          this.hovering.next(false);
        } else {
          window.clearTimeout(this.blockReset);
          this.blockReset = window.setTimeout(() => (this.blockReset = 0), 1000);
          newPosition = this.getValueFromPosition(pos);
        }

        if (this.inList) {
          this.dragChange.next(false);
        }

        this.answering.next(false);
        this.moving.next(false);

        this.answerValueChange.emit(newPosition);

        if (newPosition != null) {
          this.answerValueReady.emit();
        }

        this.resetValue.next(this.getInitialPosition(newPosition));
      }),
    );
  }

  private mergeEvents(
    element: Node,
    events: (keyof HTMLElementEventMap)[],
    init?: boolean,
    setOffset?: boolean,
  ): Observable<EventPosition> {
    return raceFromEvent(element, events).pipe(
      takeWhile(() => !this.disabled && this.isActive),
      map((event: MouseEvent | TouchEvent) => (this.isTouchEvent(event) ? event.touches[0] : event)),
      map((event) => event || { clientX: 0, clientY: 0, target: null }),
      tap(({ clientX, clientY, target }) => {
        if (init && this.thumb && this.answerArea) {
          const sliderThumb = this.thumb.nativeElement;
          this.areaRect = this.answerArea.nativeElement.getBoundingClientRect();
          this.offset = {};

          // making sure thumb doesn't snap when starting move on thumb
          if (setOffset && target instanceof HTMLElement) {
            if (sliderThumb === target || sliderThumb === target.parentElement) {
              const thumbRect = sliderThumb.getBoundingClientRect();
              this.offset.y = clientY - thumbRect.top - thumbRect.height / 2;
              this.offset.x = clientX - thumbRect.left - thumbRect.width / 2;
            }
          }
        }
      }),
      map(({ clientX, clientY }) => ({
        top: Math.min(1, Math.max(0, clientY - this.areaRect.top - (this.offset.y || 0)) / this.areaRect.height),
        left: Math.min(1, Math.max(0, clientX - this.areaRect.left - (this.offset.x || 0)) / this.areaRect.width),
      })),
      filter(() => this.isActive),
    );
  }

  private getInitialPosition(value?: number[]): EventPosition {
    const answer = value?.map((val) => val.toString()) || this.answerValue;

    if (answer !== null) {
      return this.getPositionFromValue(answer);
    } else {
      const { sliderValuesX, sliderValuesY } = this.data;

      return {
        top: sliderValuesY && sliderValuesY.initial != null ? sliderValuesY.initial : 0.5,
        left: sliderValuesX && sliderValuesX.initial != null ? sliderValuesX.initial : 0.5,
      };
    }
  }

  private getPositionFromValue(value: string[] | null): EventPosition {
    const [x, y] = (value || []).map((p) => parseFloat(p));
    let left = 0.5;
    let top = 0.5;

    if (x != null && y != null) {
      const { xMin, yMin } = this.getMinMax();
      const { xRange, yRange } = this.getRanges();

      left = Math.min(1, Math.max(0, (x - xMin) / xRange));
      top = 1 - Math.min(1, Math.max(0, (y - yMin) / yRange));
    }

    return { left, top };
  }

  private getValueFromPosition({ left, top }: EventPosition): [number, number] {
    const { xMin, yMin } = this.getMinMax();
    const { xRange, yRange } = this.getRanges();
    const { stepX, stepY } = this.getSteps();

    return [
      Math.round(Math.round((xRange * left + xMin) / stepX) * stepX * this.rounder) / this.rounder,
      Math.round(Math.round((yRange * (1 - top) + yMin) / stepY) * stepY * this.rounder) / this.rounder,
    ];
  }

  private getRanges(): { xRange: number; yRange: number } {
    const { xMin, xMax, yMin, yMax } = this.getMinMax();

    return {
      xRange: xMax - xMin,
      yRange: yMax - yMin,
    };
  }

  private getMinMax(): { xMin: number; xMax: number; yMin: number; yMax: number } {
    return {
      xMin: this.data.sliderValuesX && this.data.sliderValuesX.min != null ? this.data.sliderValuesX.min : 0,
      xMax: this.data.sliderValuesX && this.data.sliderValuesX.max != null ? this.data.sliderValuesX.max : 100,
      yMin: this.data.sliderValuesY && this.data.sliderValuesY.min != null ? this.data.sliderValuesY.min : 0,
      yMax: this.data.sliderValuesY && this.data.sliderValuesY.max != null ? this.data.sliderValuesY.max : 100,
    };
  }

  private getSteps(): { stepX: number; stepY: number } {
    const step = (range) => (range > 100 ? Math.round(range / 1000) * 10 : range > 10 ? 1 : 0.1);

    const fallbackStepX = step(
      ((this.data.sliderValuesX && this.data.sliderValuesX.max) || 100) -
        ((this.data.sliderValuesX && this.data.sliderValuesX.min) || 0),
    );

    const fallbackStepY = step(
      ((this.data.sliderValuesY && this.data.sliderValuesY.max) || 100) -
        ((this.data.sliderValuesY && this.data.sliderValuesY.min) || 0),
    );

    return {
      stepX:
        this.data.sliderValuesX && this.data.sliderValuesX.step != null
          ? this.data.sliderValuesX.step || fallbackStepX
          : fallbackStepX,
      stepY:
        this.data.sliderValuesY && this.data.sliderValuesY.step != null
          ? this.data.sliderValuesY.step || fallbackStepY
          : fallbackStepY,
    };
  }

  private hasAnswer(): boolean {
    return !!this.answerValue && this.answerValue[0] != null && this.answerValue[1] != null;
  }

  isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
  }

  noop(): void {}
}
