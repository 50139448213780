<div class="z-fx-col outcome-card">
  <div class="texts">
    <h3 class="title z-fx-sc z-fx-gap-8">
      <ng-container *ngIf="outcomeData?.type === types.OUTCOME">
        <span *ngIf="(outcomeRank | async) != null">
          {{ outcomeRank | async | json }}
        </span>
      </ng-container>

      <span [innerHtml]="outcomeData.title | translateText: 'title' : outcomeData | formatText | safeHtml"></span>

      <ng-container *ngIf="outcomeData?.type === types.OUTCOME">
        <span *ngIf="(outcomeScore | async) != null">
          {{ outcomeScore | async | percent: '1.0-0' }}
        </span>
      </ng-container>
    </h3>

    <div class="z-fx-col share-and-description">
      <div
        class="description"
        [innerHtml]="outcomeData.content | translateText: 'content' : outcomeData | formatText | safeHtml"
      ></div>
      <div class="share-buttons">
        <ng-container *ngIf="servicesList | async as services">
          <ng-container *ngIf="(ss.sharing | async).enabled && services.length > 0" class="share-box">
            <button (click)="showShareButtons = !showShareButtons" class="share-button" aria-expanded="true">
              {{ 'Share' | translateText: 'share' }}
            </button>
            <div *ngIf="showShareButtons" class="share-area z-fx">
              <button *ngFor="let service of services" class="social-share-button" (click)="onShareSurvey(service)">
                <mat-icon ngClass="share-icon" fontSet="zefplayer" [attr.aria-hidden]="true">
                  {{ service.icon }}
                </mat-icon>
                {{ service.name }}
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <media-cta [data]="outcomeData" (newAnswerer)="newAnswerer.emit()"></media-cta>
    </div>
  </div>
</div>
