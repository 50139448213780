<!--
  Outcome card view with header and content elements.

  @unstable
-->

<div *ngIf="outcomeData" class="z-container-box z-fx-col z-fx-gap-24">
  <div
    *ngIf="(ss.isFree | async) === false && (ss.logo | async).url && outcomeData?.type !== types.OUTCOME"
    class="z-team-image"
    [ngStyle]="(ss.logo | async).styles"
    [style.background-image]="(ss.logo | async).image | safeStyle"
  ></div>

  <div class="z-outcome-header z-fx-col z-fx-gap-8 z-fx-center-center z-fx-none">
    <media-cta [data]="outcomeData" (newAnswerer)="newAnswerer.emit()">
      <ng-container *ngIf="outcomeData?.type === types.OUTCOME">
        <div
          *ngIf="(outcomeRank | async) != null; else showScore"
          class="z-score-text z-texts-header z-fx-center-center"
          [style.color]="(ss.colors | async).text80"
          [style.background-color]="(ss.colors | async).mood"
        >
          {{ outcomeRank | async | json }}
        </div>

        <ng-template #showScore>
          <div
            *ngIf="(outcomeScore | async) != null; else showCorrect"
            class="z-score-text z-text-player z-fx-center-center"
            [style.color]="(ss.colors | async).text80"
            [style.background-color]="(ss.colors | async).mood"
          >
            {{ outcomeScore | async | percent: '1.0-0' }}
          </div>
        </ng-template>

        <ng-template #showCorrect>
          <div
            *ngIf="(outcomeCorrect | async) != null"
            class="z-score-text z-text-player z-fx-center-center"
            [style.color]="(ss.colors | async).primary"
            [style.background-color]="(ss.colors | async).mood80"
          >
            {{ outcomeCorrect | async }}
          </div>
        </ng-template>
      </ng-container>
    </media-cta>

    <ng-container
      *ngIf="(outcomeData?.type === types.OUTCOME && isFirstOutcome) || outcomeData?.type === types.GOODBYE"
    >
      <basic-button
        *ngIf="ss.restartDelay$ | async as delay"
        class="z-cta-button z-restart-cta"
        (click)="openCtaLink('restart')"
      >
        {{ 'Restart' | translateText: 'restartSurvey' }} ({{ delay }})
      </basic-button>
    </ng-container>
  </div>

  <div class="z-fx-col z-fx-gap-24 z-fx-auto">
    <div class="z-fx-col z-fx-gap-16">
      <div
        *ngIf="outcomeData.showTitle !== false"
        class="z-texts-header z-fx-auto"
        [innerHTML]="outcomeData.title | translateText: 'title' : outcomeData | formatText | safeHtml"
        [style.color]="(ss.colors | async).text"
      ></div>

      <share-buttons [outcomeData]="outcomeData"></share-buttons>
    </div>

    <div
      class="z-texts-paragraph z-fx-auto"
      [innerHTML]="outcomeData?.content | translateText: 'content' : outcomeData | formatText | safeHtml"
      [style.color]="(ss.colors | async).text"
    ></div>

    <promo-text *ngIf="ss.showPromoText | async"></promo-text>
  </div>
</div>
