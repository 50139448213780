<div class="z-fx-col container" [class.smileyMargin]="data.sliderSmileys">
  <div class="z-fx-col labels">
    <div class="axis-label">{{ data.sliderLabels.axis | translateText: 'axis' : data }}</div>
    <div class="z-fx z-fx-between-center axis-ends">
      <div class="min">{{ data.sliderLabels.min | translateText: 'min' : data }} ({{ data.sliderValues.min }})</div>
      <div class="max">{{ data.sliderLabels.max | translateText: 'max' : data }} ({{ data.sliderValues.max }})</div>
    </div>
  </div>
  <mat-slider
    #xAxis
    class="slider"
    [ngClass]="smiley(xAxis.value, data.sliderValues)"
    [min]="data.sliderValues.min"
    [max]="data.sliderValues.max"
    [step]="data.sliderValues.step"
    [value]="answer"
    thumbLabel
    [attr.aria-label]="ariaLabel | plainText"
    (change)="onValueChanged($event)"
  ></mat-slider>
</div>
